import {Mission} from "./sections/Mission";
import {PageSectionOptions} from "./constants";
import {Addictions} from "./sections/Addictions";
import {Product} from "./sections/Product";
import {Partners} from "./sections/Partners";
import {About} from "./sections/About";
import {WorkWithUs} from "./sections/WorkWithUs";
import {Contact} from "./sections/Contact";

type SectionDef = {
    Component: React.ComponentType<PageSectionOptions>;
    id: string;
    title: string;
}
export const Sections: SectionDef[] = [
    {Component: Mission, id: "mission", title: "Mission"},
    {Component: Addictions, id: "addictions", title: "Addictions"},
    {Component: Product, id: "product", title: "Product"},
    {Component: Partners, id: "partners", title: "Partners"},
    {Component: WorkWithUs, id: "workwithus", title: "Work With Us"},
    {Component: About, id: "about", title: "About"},
    {Component: Contact, id: "contact", title: "Contact Us"},
];
