import * as React from 'react';
import {PageSectionOptions} from "../constants";
import {PageSection} from "../components/PageSection";
// @ts-ignore
import imgSrc from '../images/undraw_Devices.svg';
import {Link} from "../components/Link";

export function Product(props: PageSectionOptions) {
    return <PageSection img={imgSrc} {...props}>
        <h3>What exactly is WanaBuddy?</h3>
        <p>Our goal is to make it as seamless as possible for WanaBuddy to be part of the user's internet experience.</p>
        <p>We need to present options in the moment of temptation. Given the privacy controls and protections that user's enjoy today, this will have to be opt-in. Users will likely need to install or configure the appropriate WanaBuddy products, depending on their usage patterns.</p>
        <h3>What The user Experiences</h3>
        <p>The aim is to identify the exact moment when a user starts an addictive 'session' and proactively engage them with a 'buddy' approach. At the very least, we can offer therapeutically-informed, non-coercive alternatives.</p>
        <p>The user can choose an alternative, or choose to continue with their addiction. Our goal is to be a buddy, not a 'hard' block. Addicts know how to work around such blockers and they don't help in the long-term.</p>
        <p>Our priority is to be a therapeutically helpful ally. We are <em>always</em> on the side of the addict, working <em>with</em> them, not against them.</p>
        <h3>How can I get it?</h3>
        <p>WanaBuddy is currently in the prototyping stage.</p>
        <p>That means we are actively working on a first public release.</p>
        <p><em>If you are interested in testing out the beta version, <Link to={'/#contact'} variant={"linkStyle"}>do get in touch.</Link></em></p>
    </PageSection>;
}
