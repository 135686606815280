import * as React from 'react';
import {ReactNode} from 'react';
import {Media, PageSectionOptions, Palette} from "../constants";
import {PageSection, PageSectionContent} from "../components/PageSection";
import styled from 'styled-components';
// @ts-ignore
import Alastair from "../images/alastair.jpg";
import classnames from 'classnames';


export function About(props: PageSectionOptions) {
    return <PageSection {...props}>
        <Container>
            <Profiles>
                <Profile name={"Alastair Brayne"} role={"Founder, Developer, Psychotherapist"} imgSrc={Alastair}>
                    <p>Alastair has been developing software for over 15 years and is just completing his training as a psychotherapist.</p>
                    <p>He is as old as one can be to have the experience of growing up with the internet in the bedroom as a teenager.</p>
                    <p>As a result, he has battled with porn as a high-functioning addict all his adult life, and suffered the impacts.</p>
                    <p>With the support and love of good friends, family and his partner, he has overcome his addictions and knows it is possible, though hard.</p>
                    <p>WanaBuddy is Alastair's soul-calling. His mission: to help his fellow addicts in those dark moments he knows so well - wishing something like this had been there for him.</p>
                </Profile>
                {/*<Profile name={"Tamson Amara"} role={"Psychological Coach"} imgSrc={Tamson} website={{url: "https://amaralife.com", label: "Amara Coaching & Training"}}>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*</Profile>*/}
                {/*<Profile name={"Miles Chesney"} role={"Strategy Consultant"} imgSrc={Miles}>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu non diam phasellus vestibulum lorem.</p>*/}
                {/*</Profile>*/}
            </Profiles>
        </Container>
    </PageSection>;
}

const Container = styled(PageSectionContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
  
  @media (${Media.tablet}) {
    flex-direction: row;        
  }
  
  @media (${Media.desktop}) {
    align-items: center;
    justify-content: center;
    padding: 0 6rem;    
}};    
  }
`;

const Profiles = styled.div`
  display: flex;
  flex-direction: column;
    
  @media (${Media.tablet}) {
    flex-direction: row;  
  }
  
`;

type ProfileProps = {
    className?: string;
    name: string;
    role: string;
    imgSrc: string;
    children: ReactNode;
}

function Profile({name, role, imgSrc, className, children}: ProfileProps) {
    return <ProfileCard
        className={classnames('card', className)}
        title={name}
        subTitle={role}
        header={<img src={imgSrc}/>}
    >
        <ProfileShadow/>
        <ProfileImage src={imgSrc}/>
        <ProfileMeta className={'p-card'}>
            <div className={'p-card-title'}>{name}</div>
            <div className={'p-card-subtitle'}>{role}</div>
        </ProfileMeta>
        <ProfileText>
            {children}
        </ProfileText>
    </ProfileCard>
}

const ShadowCSS = `box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);`;

const ProfileCard = styled.div`
  flex: 1 1 1rem;
  display: grid;

  grid-template-areas:
      "photo"
      "meta"
      "text"
  ;
  ${ShadowCSS};

  @media (${Media.tablet}) {
    width: 40rem;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "photo meta"
      "text text"
    ;
    align-items: center;
  }
  @media (${Media.desktop}) {
    width: 100%;
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "photo meta"
      "photo text"
    ;
    padding-left: 0;
  }
`;

const ProfileShadow = styled.div`
  // ${ShadowCSS};
  @media (${Media.tablet}) {
    height: 100%;
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    border-bottom: 1px solid #${Palette.Platinum.hex};
  }
  @media (${Media.desktop}) {
    display: none;
  }
`;

const ProfileImage = styled.img`
  grid-area: photo;
  object-fit: cover;
  width: 100%;
  @media (${Media.tablet}) {

  }
  @media (${Media.desktop}) {
  }
`;

const ProfileMeta = styled.div`
  box-shadow: none;
  grid-area: meta;
  padding: 1rem;
  @media (${Media.tablet}) {
    padding: 2rem;
  }
  @media (${Media.desktop}) {
    padding: 2rem 2rem 0 2rem;
  }
`;

const ProfileText = styled.div`
  grid-area: text;
  padding: 0 1rem 1rem 1rem;
  @media (${Media.tablet}) {
    padding: 1rem 2rem 2rem 2rem;
  }
  @media (${Media.desktop}) {
    box-shadow: none;
    padding: 0 2rem 2rem 2rem;
  }
`;
