import * as React from 'react';
import {PageSectionOptions} from "../constants";
import {PageSection} from "../components/PageSection";
// @ts-ignore
import imgSrc from '../images/undraw_Puzzle.svg';
import {CardBlock} from "../components/CardBlock";

export function Addictions(props: PageSectionOptions) {
    return <PageSection img={imgSrc} {...props}>
        <h3>What We Support</h3>
        <p>Our first supported addiction is:</p>
        <CardBlock cards={[
            {title: "Porn"},
        ]}/>
        <p>We plan to expand to <strong>gambling</strong>, <strong>social media</strong>, <strong>news</strong> and <strong>gaming</strong>.</p>
    </PageSection>;
}
