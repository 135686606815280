import * as React from 'react';
import styled from 'styled-components';
import {Media, Palette} from "../constants";
import {Link} from "./Link";

export type FooterProps = {
    className?: string;
}

export function Footer({className}: FooterProps) {
    return <Container className={className}>
        <Left>
            <Title>WanaBuddy</Title>
            <Company>A project by <Link to={"https://buddyapps.co"}>The Buddy Apps Imperative Ltd</Link></Company>
            <Copyright>&copy; 2021</Copyright>
            <FooterLinks>
                <Link to={'/privacy'}>Privacy Policy</Link>
                <Link to={'/terms'}>Terms &amp; Conditions</Link>
            </FooterLinks>
        </Left>
        <Social>
            <div className="s9-widget-wrapper"></div>
        </Social>
    </Container>;
}

const Container = styled.footer`
  width: 100%;
  flex: 0 0 auto;
  background-color: #${Palette.MaximumYellowRed.hex};
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  font-size: 0.8rem;
  padding: 1rem;
  @media (${Media.desktop}) {
    margin-top: 10rem;  
  }
`;

const Left = styled.div`
  flex: 0 0 auto;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;
const Company = styled.div`
  color: #${Palette.BlueYonder.hex};
  & a {
    font-family: 'lato', 'nunito', sans-serif;
    font-weight: 700;
  }  
`;
const Copyright = styled.div`
  color: #${Palette.BlueYonder.hex};  
`;
const Social = styled.div`
  flex: 0 0 auto;
`;
const FooterLinks = styled.div`
  margin: 1rem 0;
  
  display: flex;
  flex-direction: column;
  
  & > * {
    line-height: 1.2rem
  }
`;
