import * as React from 'react';
import {Button} from "primereact/button";
import styled from 'styled-components';
import {AppBarHeight, CenterSectionCSS, Media, Palette} from "../constants";
import {Link, LinkProps} from "./Link";
import {Sections} from "../sections";
import {Sidebar} from "primereact/sidebar";
import {atom, useRecoilState, useSetRecoilState} from "recoil";

export type AppBarProps = {
    className?: string;
}

const sidebarState = atom({
    key: 'sidebarVisible',
    default: false,
});
export function AppBar({className}: AppBarProps) {
    const [visible, setVisible] = useRecoilState(sidebarState);
    const navContent = <React.Fragment>
        <NavButton to={'/#home'}>Home</NavButton>
        {Sections.map((section, i) => <NavButton key={i} to={`/#${section.id}`}>{section.title}</NavButton>)}
    </React.Fragment>;
    return <Container className={className}>
        <Content>
            <Title><Link to={"/#home"}>WanaBuddy</Link></Title>
            <DesktopNav>
                {navContent}
            </DesktopNav>
            <MobileNav>
                <Button icon="pi pi-bars" label={"menu"} onClick={() => setVisible(true)} className={'p-button p-button-text p-button-secondary'}/>
                <Sidebar visible={visible} onHide={() => setVisible(false)} position={"right"} className="ui-sidebar-sm">
                    <OverlayContent>
                        {navContent}
                    </OverlayContent>
                </Sidebar>
            </MobileNav>
        </Content>
    </Container>
}


const Container = styled.header`
  position: fixed;
  flex: 0 0 auto;
  height: ${AppBarHeight}rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #${Palette.MaximumYellowRed.hex};
  z-index: 999;    
`;
const Content = styled.div`
  ${CenterSectionCSS};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding: 1rem;
  
  @media (${Media.desktop}) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  
  @media (${Media.desktop}) {
    font-size: 2rem;
  }
`;

const DesktopNav = styled.nav`
  display: none;  
  
  @media (${Media.desktop}) {
    display: flex;
    flex-direction: row;
    & > * {
      margin-left: 1rem;
    }
  }
`;

const MobileNav = styled.div`
  @media (${Media.desktop}) {
    display: none;
  }
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
`;

function NavButton({children, ...props}: LinkProps) {
    const setVisible = useSetRecoilState(sidebarState);
    return <Link onAnchorLinkClick={() => setVisible(false)} {...props}>
        <Button className={'p-button-text p-button-secondary'}>{children}</Button>
    </Link>
}
