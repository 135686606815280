import * as React from 'react';
import {PageSectionOptions} from "../constants";
import {PageSection} from "../components/PageSection";
// @ts-ignore
import imgSrc from '../images/undraw_Team.svg';
import {CardBlock} from "../components/CardBlock";
import {Link} from "../components/Link";

export function WorkWithUs(props: PageSectionOptions) {
    return <PageSection img={imgSrc} {...props}>
        <h3>We're Looking For Help</h3>
        <p>We are particularly interested in people with the following skillsets:</p>
        <CardBlock cards={[
            {title: "Marketing"},
            {title: "Finance"}
        ]}/>
        <p><em>If you would like to know more, <Link to={"/#contact"} variant={"linkStyle"}>send a message</Link>.</em></p>
    </PageSection>;
}
