import * as React from 'react';
import {PageSectionOptions} from "../constants";
import {PageSection} from "../components/PageSection";
// @ts-ignore
import imgSrc from '../images/undraw_Agreement.svg';
import {Link} from "../components/Link";

export function Partners(props: PageSectionOptions) {
    return <PageSection img={imgSrc} {...props}>
        <h3>Solving The Challenges Together</h3>
        <h4>Delivery Partners</h4>
        <p>We actively seek to expand our providers of alternatives for addicts. This could include:</p>
        <ul>
            <li>Apps offering support for addicts</li>
            <li>Online support communities</li>
            <li>Webinars, online programmes and resources</li>
            <li>Charities and social enterprises working with porn addiction, or interested in developing greater expertise in meeting addicts online</li>
        </ul>
        <h4>Funding Bodies</h4>
        <p>We are always interested in exploring partnerships with charities, funding entities, and government bodies who are seeking to make a tangible difference to a large number of users.</p>
        <h4>Technical Collaborations</h4>
        <p>WanaBuddy is interested in working with technology partners to expand our ability and expertise in meeting addicts at their point of need.</p>
        <p></p>
        <p><em>If you are interested in discussing possible partnerships, please <Link to={'/#contact'} variant={"linkStyle"}>do say hello.</Link></em></p>
    </PageSection>;
}
