import * as React from 'react';
// @ts-ignore
import imgSrc from "../images/undraw_BigPhone.svg";
import {PageSection} from "../components/PageSection";
import {PageSectionOptions} from "../constants";

export function Mission(props: PageSectionOptions) {
    return <PageSection img={imgSrc} {...props}>
        <h3>This is for all the Internet Addicts</h3>
        <p>WanaBuddy was born from personal experience;</p>
        <p>At some level, most addicts don't <em>want</em> to be addicts. But for deep psychological reasons it can feel impossibly difficult to 'just quit'.</p>
        <p>No matter how many tools, strategies and support networks we learn and build, there are times when we open our laptops or turn on our phones and... in that moment, we have already lost the fight.</p>
        <p>Sure, we could theoretically reach out for support. But as every addict knows, the shame, guilt, despair (and sometimes just the time of night) means we simply cannot.</p>
        <p><strong>What we really need is a buddy</strong> to step in on our behalf and catch us in that moment. And we need them <em>right there and then</em>.</p>
        <p>We need someone to have our backs. Someone who gets it. Someone who can help us make a different choice with kindness and compassion. Someone who will not judge us or make the shame worse.</p>
        <p>WanaBuddy is that someone.</p>
        <p>It may not be a person, but it was born from personal experience, and it will be there in those moments, proactively helping make a different choice. It is a hand reached out in support to fellow addicts.</p>
        <p>We get it. We've got your back, in the moments when you need it the most.</p>
    </PageSection>
}
