import * as React from 'react';
import styled from 'styled-components';
import {Card} from "primereact/card";
import {Media} from "../constants";


export type CardDef = {
    title: string
}
export type CardBlockProps = {
    cards: CardDef[]
}
export function CardBlock({cards}: CardBlockProps) {
    return <Container>
        {cards.map(({title}, i) => (<Card key={i} title={title}/>))}
    </Container>
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  & > * {
    margin-bottom: 1rem;
    @media (${Media.tablet}) {
      margin-right: 1rem;
      margin-bottom: 0rem;
    }
  }
  
  @media (${Media.tablet}) {
    width: inherit;
    flex-direction: row;
    align-items: flex-start;
  }
`;

