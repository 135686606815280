import * as React from 'react';
import {Media, PageSectionOptions} from "../constants";
import {PageSection} from "../components/PageSection";
import styled from 'styled-components';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
// @ts-ignore
import imgSrc from "../images/undraw_Envelope.svg";


export function Contact(props: PageSectionOptions) {
    return <Container {...props} img={imgSrc}>
        <h3>Say Hi</h3>
        <Form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" action={'/ContactSuccess'}>
            <input type="hidden" name="bot-field"/>
            <input type="hidden" name="form-name" value="contact"/>

            <FieldRow className="p-float-label">
                <InputText id="name" name={"name"}/>
                <label htmlFor="name">Name</label>
            </FieldRow>
            <FieldRow className="p-float-label">
                <InputText id="email" keyfilter={"email"} name={"email"}/>
                <label htmlFor="email">Email</label>
            </FieldRow>
            <FieldRow className="p-float-label">
                <InputTextarea id={"message"} rows={5} cols={30} name={"message"}/>
                <label htmlFor="message">Message</label>
            </FieldRow>
            <Submit icon="pi pi-send" type={"submit"} label={"Submit"}/>
        </Form>
    </Container>;
}

const Container = styled(PageSection)`
  &:after { 
    border: none;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

const FieldRow = styled.div`
  margin-top: 2rem;
  width: 100%;
  
  input, textarea {
    width: 100%;
  }
  @media (${Media.desktop}) {
    input, textarea {  
      width: 50rem;        
    }
  }
`;

const Submit = styled(Button)`
  width: 10rem;
  margin-top: 2rem;
`;
